import { http, HttpResponse } from "msw";
import { PUBLIC_API_BASE_URL } from "$env/static/public";

export const getMovieDetail = http.get(`${PUBLIC_API_BASE_URL}/api/info/getMovieDetail/:id`, () => {
  return HttpResponse.json({
    data: {
      title: {
        cast: [
          "Anke Engelke",
          "Til Schweiger",
          "Dietmar Bär",
          "Santiago Ziesmer",
          "Friedrich Schoenfelder",
          "Ralf Schmitz",
          "Mirco Nontschew"
        ],
        directors: ["Guido Schmelich", "Martin Otevrel"],
        duration: 70,
        genres: ["Animation", "Family"],
        locale: "en_US",
        name: "Oh, wie schön ist Panama",
        original_languages: [],
        original_name: "Oh, wie schön ist Panama",
        production_countries: ["Germany"],
        release_year: 2006,
        streaming_availabilities: [
          {
            audios: [],
            currency: "CHF",
            monetization_type: "RENT",
            price: null,
            region: "CH",
            streamer: "Apple TV",
            subtitles: [],
            url: "https://tv.apple.com/ch/movie/janosch-oh-wie-schon-ist-panama/umc.cmc.2ri232v4kzsjk8k7eusoiom7o"
          }
        ],
        synopsis:
          'The two friends Tiger and Bear live happily in a cottage near a river. The Bear is a passionate fisherman, and the Tiger is a skilled mushroom picker who owns a little yellow and black striped wooden duck with wheels. He calls it "Tigerduck" (Tigerente in German). One day, the Bear fishes an empty wooden crate with the inscription "Panama" out of the river. He sniffs the crate, and it smells like bananas, leading him to believe that Panama is the land of his dreams. The Bear enthusiastically tells his friend Tiger that Panama is a place where everything is supposed to be better, bigger, and more beautiful than home, and that they should travel to this land now. The next day, the two set off to Panama with a cooking pot, a fishing rod, a hat, and the Tigerduck.',
        videos: []
      }
    },
    message: "Success",
    status: 200
  });
});
