import { http, HttpResponse } from "msw";

export const getMovieIcon = http.get(
  "https://mowizzdevstorage.blob.core.windows.net/mowizzblobstorage/images/:id",
  async () => {
    const encoder = new TextEncoder();
    const svgBytes = encoder.encode(posterPlaceholder);

    return HttpResponse.arrayBuffer(svgBytes.buffer, {
      headers: {
        "Content-Type": "image/svg+xml"
      }
    });
  }
);

const posterPlaceholder = `
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
  fill="#ffffff" stroke="none">
  <path d="M384 4183 c-12 -2 -32 -15 -45 -29 l-24 -26 0 -1569 0 -1569 28 -27
  27 -28 2190 0 2190 0 27 28 28 27 0 1570 0 1570 -28 27 -27 28 -2173 1 c-1194
  1 -2182 -1 -2193 -3z m564 -320 l3 -123 -221 0 -220 0 0 118 c0 65 3 122 7
  126 4 3 102 5 217 4 l211 -3 3 -122z m3022 -1303 l0 -1430 -1410 0 -1410 0 0
  1430 0 1430 1410 0 1410 0 0 -1430z m638 1303 l3 -123 -221 0 -220 0 0 118 c0
  65 3 122 7 126 4 3 102 5 217 4 l211 -3 3 -122z m-3658 -498 l0 -165 -220 0
  -220 0 0 165 0 165 220 0 220 0 0 -165z m3660 0 l0 -165 -220 0 -220 0 0 165
  0 165 220 0 220 0 0 -165z m-3665 -535 l0 -165 -217 -3 -218 -2 0 163 c0 90 3
  167 7 171 4 3 102 5 217 4 l211 -3 0 -165z m3663 -3 l2 -167 -217 2 -218 3 -3
  155 c-1 85 0 160 2 167 4 11 50 13 218 11 l213 -3 3 -168z m-3663 -537 l0
  -165 -215 0 -215 0 -3 168 -2 167 217 -2 218 -3 0 -165z m3663 3 l-3 -168
  -215 0 -215 0 -3 155 c-1 85 0 160 2 167 4 10 54 13 221 13 l215 0 -2 -167z
  m-3658 -538 l0 -165 -220 0 -220 0 0 165 0 165 220 0 220 0 0 -165z m3660 0
  l0 -165 -220 0 -220 0 0 165 0 165 220 0 220 0 0 -165z m-3662 -497 l-3 -123
  -215 0 -215 0 -3 123 -3 122 221 0 221 0 -3 -122z m3660 0 l-3 -123 -215 0
  -215 0 -3 123 -3 122 221 0 221 0 -3 -122z"/>
  <path d="M1959 3232 l-34 -30 0 -642 0 -642 34 -30 c28 -25 40 -29 70 -25 41
  6 1101 594 1141 632 18 18 25 36 25 65 0 29 -7 47 -25 65 -40 38 -1100 626
  -1141 632 -30 4 -42 0 -70 -25z m552 -458 c203 -114 369 -210 369 -214 0 -8
  -735 -420 -750 -420 -7 0 -10 143 -10 420 0 290 3 420 11 420 5 0 176 -93 380
  -206z"/>
  </g>
  </svg>
`;
