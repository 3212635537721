import { http, HttpResponse } from "msw";
import { PUBLIC_API_BASE_URL } from "$env/static/public";

export const getVersion = http.get(`${PUBLIC_API_BASE_URL}/api/info/getVersion`, () => {
  return HttpResponse.json({
    data: {
      environment: "integrationtest",
      version: "v0.12345.6789"
    },
    message: "Success",
    status: 200
  });
});
