// Documentation link: https://kit.svelte.dev/docs/hooks

import { setupWorker } from "msw/browser";
import { handlers } from "./mocks/handlers";

if (import.meta.env.MODE === "offlineMode") {
  const worker = setupWorker(...handlers);

  await worker.start({
    onUnhandledRequest(request) {
      if (request.url.includes("svelte") || request.url.startsWith("http://localhost")) {
        return;
      }
      console.warn(`Failed to intercept ${request.url}`);
    }
  });
}

export async function handleError({ error, event, status, message }) {
  const fullError = {
    error: error,
    event: event,
    status: status,
    message: message
  };
  console.log(`Error: ${JSON.stringify(fullError, undefined, 2)}`);
}
