import { http, HttpResponse } from "msw";
import { PUBLIC_API_BASE_URL } from "$env/static/public";

export const streamerList = http.get(`${PUBLIC_API_BASE_URL}/api/info/streamerList/:country`, () => {
  return HttpResponse.json({
    data: {
      streamers: [
        {
          id: "695f4169-442b-497a-8248-74bd7d6ff9d9",
          name: "Playsuisse",
          url: "https://www.playsuisse.ch/de"
        },
        {
          id: "fc4be627-9414-4f5b-91eb-5756960aed63",
          name: "Apple TV",
          url: "https://tv.apple.com/"
        },
        {
          id: "ca0bda9b-e68e-4a2e-9555-c112a979e4d5",
          name: "Mubi",
          url: "https://www.mubi.com/"
        },
        {
          id: "b33ef188-95d9-4556-913a-e40f30c4521c",
          name: "Netflix",
          url: "https://www.netflix.com"
        },
        {
          id: "1fa54555-500e-4204-b06c-361ec2b8e453",
          name: "Kino On Demand",
          url: "https://www.kino-on-demand.com/"
        },
        {
          id: "9d986710-ebd1-4b9f-83e5-780f17aa4c10",
          name: "Artfilm",
          url: "https://www.artfilm.ch"
        },
        {
          id: "98d23a58-b73b-4f43-86e9-753255508287",
          name: "Cinu",
          url: "https://www.cinu.ch/"
        },
        {
          id: "c3f3eb36-bf33-496b-9c3f-ae9a79068c68",
          name: "Sooner",
          url: "https://www.sooner.be/fr"
        },
        {
          id: "16316f57-f7aa-4904-9e34-1c2749f096e2",
          name: "MyFilm",
          url: "https://www.myfilm.ch/"
        },
        {
          id: "9f352402-713c-4f6c-bd7f-750e3d125a1c",
          name: "Cinefile",
          url: "https://en.cinefile.ch/"
        },
        {
          id: "337b5edf-1cb6-4bb3-b91c-72fb3984e537",
          name: "Paramount+",
          url: "https://www.paramountplus.com/"
        },
        {
          id: "066d56fd-3f72-4e5b-9309-3ab1bfb7115e",
          name: "Amazon Prime Video",
          url: "https://www.primevideo.com/"
        },
        {
          id: "1c9fec31-5776-4b96-b6b6-45fc9b8f1527",
          name: "Filmingo",
          url: "https://www.filmingo.ch/en"
        }
      ]
    },
    message: "Success",
    status: 200
  });
});
