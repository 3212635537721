import { http, HttpResponse } from "msw";
import { PUBLIC_API_BASE_URL } from "$env/static/public";

export const getTagsFromPrompt = http.get(
  `${PUBLIC_API_BASE_URL}/api/search/getTagsFromPrompt/:prompt`,
  ({ params }) => {
    const { prompt } = params;
    return HttpResponse.json({
      data: {
        tags: [
          {
            category: "plot",
            displayText: prompt,
            id: "b6b28f5a-50dc-4c25-a2e1-a9ff7a6f09b5",
            incl_excl: "INCLUDE",
            type: "AD_HOC"
          }
        ]
      },
      message: "Success",
      status: 200
    });
  }
);
